import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router); // Angular Router injection

  // Check for user details in localStorage
  const user = localStorage.getItem('user');

  if (user) {
    // User details found in localStorage, allow access to the route
    return true;
  } else {
    // User details not found, redirect to login page
    router.navigate(['/authentication/login']);
    return false; // Block access to the route
  }
};
