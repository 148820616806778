
<div class="order-session-container">
    <div class="order-session">
      <h1 class="order-id">Order ID: <span>{{ orderId }}</span></h1>
      <h1 class="session-id">Session ID: <span>{{ sessionID }}</span></h1>
    </div>
  


<app-session-details
[sessionData]="sessionData"
[isSessionAvailable]="isSessionAvailable"
>
</app-session-details>