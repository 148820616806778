import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

interface User {
  userId: string; // Firestore UID or converted API numeric ID
  name: string;
  role?: string;
  email?: string;
  cardId?: string;
  phone?: string;
  note?: string;
}

@Injectable({
  providedIn: 'root',
})


export class AuthService {
  private baseUrl = environment.environmentWeb.baseUrl; // Set the base API URL from environment
  private apiKey = environment.environmentWeb.secretKey; // API secret key from environment
  private userMap: { [key: number]: string } = {}; // Map to store user ID and names

  constructor(private http: HttpClient,
    private router: Router) {}

  /**
   * Login API Call
   * 
   * @param email - User's email address
   * @param password - User's password
   * @returns Observable with login response
   */
  login(email: string, password: string): Observable<any> {
    const url = `${this.baseUrl}/users/login`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': this.apiKey, // Add the secret key in the headers
    });

    const body = {
      email,
      password,
    };

    return this.http.post(url, body, { headers });
  }

  /**
   * Logout Logic (if required, e.g., clear tokens or session)
   */
  logout(): void {
    localStorage.removeItem('user');
    this.router.navigate(['/authentication/side-login']);
    // Additional logout logic can be added here (e.g., API logout call)
  }




  /**
 * Fetch user details by user ID.
 * 
 * @param userId - The ID of the user to fetch details for
 * @returns Observable with user details
 */
getUserDetails(userId: string): Observable<any> {
  const url = `${this.baseUrl}/users/user/${userId}`;
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Api-Key': this.apiKey, // Add the secret key in the headers
  });

  return this.http.get(url, { headers });
}

 /**
   * Fetch all active users and store in userMap.
   */
 loadActiveUsers(): Observable<User[]> {
  const url = `${this.baseUrl}/users/active`;
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Api-Key': this.apiKey,
  });

  return this.http.get<{ id: number; name: string }[]>(url, { headers }).pipe(
    tap((users) => {
      // Populate the userMap for quick lookup
      this.userMap = {};
      users.forEach((user) => {
        this.userMap[user.id] = user.name;
      });
    }),
    // Map the API response to match the structure of your User model
    map((users: any[]) =>
      users.map((user) => ({
        userId: user.id.toString(), // Convert numeric ID to string to match the Firestore structure
        name: user.name,
        role: '', // Default to empty or populate if API includes a 'role'
        email: '', // Default to empty or populate if API includes an 'email'
        cardId: '', // Default to empty or populate if API includes a 'cardId'
        phone: '', // Default to empty or populate if API includes a 'phone'
        note: '', // Default to empty or populate if API includes a 'note'
      }))
    )
  );
}


/**
 * Get the name of a user by ID.
 * 
 * @param id - User ID
 * @returns User name or 'Unknown' if not found
 */
getUserNameById(id: number): string {
  return this.userMap[id] || 'Support User';
}


 /**
   * Create a new user.
   * 
   * @param userData - Object containing user details
   * @returns Observable with the response
   */
 createUser(userData: {
  email: string;
  phone: string;
  role: string;
  note: string;
  name: string;
  cardid?: string; // Optional field
}): Observable<any> {
  const url = `${this.baseUrl}/users/create`;
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Api-Key': this.apiKey, // Add the secret key in the headers
  });

  return this.http.post(url, userData, { headers });
}


updateUser(userId: number, userData: any): Observable<any> {
  const url = `${this.baseUrl}/users/${userId}/update`;
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Api-Key': this.apiKey,
  });

  return this.http.put(url, userData, { headers });
}


 /**
   * Reset Password API Call
   * 
   * @param email - The email address of the user requesting the password reset
   * @returns Observable with the API response
   */
  resetPassword(email: string): Observable<any> {
    const url = `${this.baseUrl}/users/reset-password?email=${encodeURIComponent(email)}`;
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'X-Api-Key': this.apiKey, // Add the secret key in the headers
    });

    return this.http.post(url, {}, { headers });
  }
}
