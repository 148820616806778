import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { WebshopserviceService } from 'src/app/services/webshopservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-session-details',
  standalone: true,
  imports: [MaterialModule, MatCardModule, CommonModule, FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    SessionDetailsComponent,
    MatChipsModule,
    MatOptionModule],
  templateUrl: './session-details.component.html',
  styleUrl: './session-details.component.scss'
})
export class SessionDetailsComponent {
  @Input() sessionData: any;
  @Input() isSessionAvailable: boolean = false;
  refundAmount: number;
  refundTransactionId: string;
  isrefundStart:boolean;

  constructor(
    private sessionService: WebshopserviceService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onRefund(sessionId: string): void {
 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: `Are you sure you want to refund CHF ${this.refundAmount} for Transaction ID ${this.refundTransactionId}?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sessionService.processRefund(sessionId, Number(this.refundTransactionId), this.refundAmount).subscribe(
          response => {
            console.log('Refund processed successfully:', response);
            this.snackBar.open('Refund processed successfully!', 'Close', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          },
          error => {
            console.error('Error processing refund:', error);
            this.snackBar.open('Error processing refund. Please try again.', 'Close', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
        );
      } else {
        console.log('Refund canceled');
      }
    });
  }

  onReprint(filePageId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { message: `Are you sure you want to reprint File Page ID ${filePageId}?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sessionService.resetPrintStatus(filePageId).subscribe(
          response => {
            console.log('Print status reset successfully:', response);
            this.snackBar.open('Print status reset successfully!', 'Close', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          },
          error => {
            console.error('Error resetting print status:', error);
            this.snackBar.open('Error resetting print status. Please try again.', 'Close', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
        );
      } else {
        console.log('Reprint canceled');
      }
    });
  }

  isPrintedPageAvailable(fileId: number): boolean {
    return this.sessionData?.printedpages?.some((p: { file_page_id: number; }) => p.file_page_id === fileId);
  }
  
  getPrintedPages(fileId: number) {
    return this.sessionData?.printedpages?.filter((p: { file_page_id: number; }) => p.file_page_id === fileId) || [];
  }
  

  refundStart(transaction_id: string) {

    if(transaction_id){
      this.refundTransactionId = transaction_id;
      this.isrefundStart = true;
    }
    /* navigator.clipboard.writeText(value).then(
      () => console.log('Copied to clipboard: ', value),
      (err) => console.error('Failed to copy: ', err)
    ); */

  }
  
  processGlobalRefund() {
    if (this.refundTransactionId && this.refundAmount > 0) {
      console.log('Processing refund for:', this.refundTransactionId, 'Amount:', this.refundAmount);
      // Add refund logic here
    }
  }
  
}

