import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StatusEnum, StatusText } from '../ticketlist/status.enum';
import { SourceEnum, SourceText } from '../ticketlist/source.enum';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TicketService } from 'src/app/services/ticket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebshopserviceService } from 'src/app/services/webshopservice.service';
import { AuthService } from 'src/app/services/authservice.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionDetailsComponent } from '../session-details/session-details.component';

@Component({
  selector: 'app-ticketview',
  standalone: true,
  imports: [MaterialModule, MatCardModule, CommonModule, FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    SessionDetailsComponent,
    MatOptionModule],
  templateUrl: './ticketview.component.html',
  styleUrl: './ticketview.component.scss'
})
export class TicketviewComponent implements OnInit {
  ticket: any = null;
  fileMetadata: any = null;
  refundAmount: number = 0;
  loading: boolean = true;
  isEditing: boolean = false;
  StatusEnum = StatusEnum; 
  StatusText = StatusText;
  SourceText = SourceText;

 currentUser = localStorage.getItem('user'); // Get the current user from localStorage

createdBy :any; 
  notes: any[] = []; // Store notes for the ticket
  newNote: string = ''; // Store the content of the new note
  newTitle:string = '';
  loadingNotes: boolean = true; // Loading state for notes


  categories: Array<{ id: number; name: string }> = []; // Adjust as per API
  sources: Array<{ id: number; name: string }> = [];
  statuses: Array<{ id: string; name: string }> = [];
  criticalities: { id: number; name: string }[] = [
    { id: 1, name: 'High' },
    { id: 2, name: 'Medium' },
    { id: 3, name: 'Low' },
  ];


  categoryMap: any;
  sessionData: any = null;
  isSessionAvailable = false;

  private baseUrl = environment.environmentWeb.baseUrl;
  userNames: { [key: string]: string } = {}; // To store resolved user names


  getSourceText(source: number): string {
    return SourceText[source as keyof typeof SourceText] || 'Unknown';
  }

  getStatusText(status: string): string {
    return StatusText[status as keyof typeof StatusText] || 'Unknown';
  }

  


  constructor(private route: ActivatedRoute, private ticketService: TicketService,private authService: AuthService,  private snackBar: MatSnackBar, private sessionService: WebshopserviceService,private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {

  // Default value in case user is not found

  if (this.currentUser) {
    const user = JSON.parse(this.currentUser); // Parse the user object
    this.createdBy =  user.id ||  user.name|| 'Unknown User'; // Use `name`, fallback to `id` if `name` is not available
  }
  
    try {
      this.loading = true;

      // Load categories
      await this.ticketService.loadCategories();
      this.categoryMap = this.ticketService.categoryMap;

      // Map categories for dropdown
      this.categories = Object.keys(this.categoryMap).map((id) => ({
        id: Number(id),
        name: this.ticketService.getCategoryText(Number(id)),
      }));

      // Fetch ticketId and fileId from query parameters
      const ticketId = this.route.snapshot.queryParamMap.get('ticketId');
      const fileId = this.route.snapshot.queryParamMap.get('fileId');

      if (!ticketId) {
        throw new Error('Ticket ID is required');
      }

      // Fetch ticket details
      this.ticket = await this.ticketService.loadTicketDetails(ticketId);

      // Load ticket notes
      await this.loadTicketNotes(Number(ticketId));

      // Fetch file details if fileId is provided
      if (fileId) {
        this.fileMetadata = await this.ticketService.loadFileDetails(fileId);
      }





      // Populate sources and statuses for dropdown
      this.sources = Object.entries(SourceText).map(([id, name]) => ({
        id: Number(id),
        name,
      }));

      this.statuses = Object.entries(StatusText).map(([id, name]) => ({
        id,
        name,
      }));

      this.criticalities = [
        { id: 1, name: 'High' },
        { id: 2, name: 'Medium' },
        { id: 3, name: 'Low' },
      ];
    } catch (error) {
      console.error('Error initializing component:', error);
    } finally {
      this.loading = false;
    }

    const sessionId = this.ticket?.sessionId;
    const onlyPaidPages = true;
   

    this.sessionService.getSessionData(sessionId, onlyPaidPages).subscribe(
      (data) => {
        this.sessionData = data;
        this.isSessionAvailable = !!data.session; // Check if session exists
        console.log('Session Data:', data);
      },
      (error) => {
        console.error('Error fetching session data:', error);
      }
    );

     if (this.notes.length  >0 ){
      this.authService.loadActiveUsers().subscribe({
        next: () => {
          this.resolveUserNames(); // Populate user names
        },
        error: (err) => console.error('Failed to load active users:', err),
      });
     }

  
    
  }


  resolveUserNames(): void {
    this.notes.forEach((note) => {
      this.userNames[note.created_by] = this.authService.getUserNameById(note.created_by);
    });
  }

  getuserName(id:any){

    const varl= this.authService.getUserNameById(Number(id));
 
   return varl;
  }

  openFile(): void {
    const url =this.baseUrl
    if (this.fileMetadata?.fileUrl) {
      const fullUrl = `${url}${this.fileMetadata.fileUrl}`;
      window.open(fullUrl, '_blank');
    } else {
      console.error('File URL is not available.');
    }
  }


  getCategoryText(categoryId: number): string {
    const category = this.categories.find((c) => c.id == categoryId);
    return category ? category.name : 'Unknown';
  }

  getCriticalityText(criticalityId: number | null | undefined): string {


    if (criticalityId == null) {
      return 'Unknown'; // Return 'Unknown' if criticalityId is null or undefined
    }


    const criticality = this.criticalities.find((c) => c.id == criticalityId);
    return criticality ? criticality.name : 'Unknown';
  }



  toggleEdit(): void {
    this.isEditing = !this.isEditing;
  }

  async saveChanges(): Promise<void> {
    if (this.ticket?.ticketId) {
      try {
        console.log('Updating ticket:', this.ticket);

        const updatedData = {
          category: this.ticket.category,
          status: this.ticket.status,
          source: this.ticket.source,
          criticality: this.ticket.criticality,
          // Add other fields that need to be updated
        };

        const previousTicket = await this.ticketService.loadTicketDetails(this.ticket.ticketUserId);

        await this.ticketService.updateTicket(this.ticket.ticketUserId, updatedData);

        // Prepare changes for the note
        const changes: string[] = [];

        if (previousTicket.category !== this.ticket.category) {
          changes.push(`Category changed from "${this.getCategoryText(previousTicket.category)}" to "${this.getCategoryText(this.ticket.category)}"`);
        }
        if (previousTicket.status !== this.ticket.status) {
          changes.push(`Status changed from "${this.getStatusText(previousTicket.status)}" to "${this.getStatusText(this.ticket.status)}"`);
        }
        if (previousTicket.source !== this.ticket.source) {
          changes.push(`Source changed from "${this.getSourceText(previousTicket.source)}" to "${this.getSourceText(this.ticket.source)}"`);
        }
        if (previousTicket.criticality !== this.ticket.criticality) {
          changes.push(`Criticality changed from "${this.getCriticalityText(previousTicket.criticality)}" to "${this.getCriticalityText(this.ticket.criticality)}"`);
        }

        if (changes.length > 0) {
          const noteContent = changes.join(', ');

         

          const noteData = {
            ticket_id: this.ticket.ticketUserId,
            action: 'Modification',
            note: noteContent,
            created_by: this.createdBy, // Dynamically set the user
          };


          // Add the note via API
          const response = await this.ticketService.createTicketNote(noteData);
          this.ticket = await this.ticketService.loadTicketDetails(this.ticket.ticketUserId);

          // Add the note to the notes array in real-time
          const newNote = {
            ...noteData,
            created_at: new Date().toISOString(), // Add timestamp for display
          };

          this.notes = [newNote, ...this.notes]; // Prepend the new note
        }

        this.isEditing = false;

        this.snackBar.open('Ticket updated successfully!', 'Close', {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });
      } catch (error) {
        console.error('Error updating ticket:', error);

        this.snackBar.open('Failed to update ticket. Please try again.', 'Close', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
      }
    }
  }





  // Method to fetch notes for the ticket
  async loadTicketNotes(ticketId: number): Promise<void> {
    try {
      this.notes = await this.ticketService.getTicketNotesByTicketId(ticketId);
    } catch (error) {
      console.error('Error loading ticket notes:', error);
    } finally {
      this.loadingNotes = false;
    }
  }

  async createNote(): Promise<void> {
    if (!this.newNote.trim()) {
      this.snackBar.open('Note cannot be empty!', 'Close', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
      return;
    }

    try {
      // Call the service method
      const response = await this.ticketService.createNote(
        this.ticket.ticketUserId,
        this.newNote,
        this.newTitle,
        this.createdBy, // Replace with the current user logic
      );

      console.log('Note created:', response);

      // Add the new note to the notes array dynamically
      const newNote = {
        ticket_id: this.ticket.ticketUserId,
        action: this.newTitle?this.newTitle: 'Internal Note',
        note: this.newNote,
        created_by:   this.createdBy,
        created_at: new Date().toISOString(), // Add a timestamp for display
      };
      this.ticket = await this.ticketService.loadTicketDetails(this.ticket.ticketUserId);
      this.notes = [newNote, ...this.notes]; // Prepend the new note to the list
      this.newNote = ''; // Clear the input field
      this.newTitle= '';
      // Show success toast
      this.snackBar.open('Note added successfully!', 'Close', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (error) {
      console.error('Error creating note:', error);

      // Show error toast
      this.snackBar.open('Failed to add note. Please try again.', 'Close', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }


 

  copyToClipboard(value: string): void {
    if (value) {
      navigator.clipboard.writeText(value).then(() => {
        this.snackBar.open('Copied to clipboard!', 'Close', {
          duration: 2000,
        });
      });
    }
  }



  

}