import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/authservice.service';
import { WebshopserviceService } from 'src/app/services/webshopservice.service';
import { SessionDetailsComponent } from '../session-details/session-details.component';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [SessionDetailsComponent],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent {
  sessionData: any = null;
  isSessionAvailable = false;
  sessionID:any;

  orderId:any;

  constructor(private route: ActivatedRoute, private authService: AuthService,  private snackBar: MatSnackBar, private sessionService: WebshopserviceService,private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    const sessionId = this.route.snapshot.queryParamMap.get('sessionId'); 
    this.sessionID = sessionId;
    this.orderId =  this.route.snapshot.queryParamMap.get('orderid');
    if (!sessionId) {
      console.error('Session ID is required but was not provided.');
      return;
    }
    this.sessionService.getSessionData(sessionId, true).subscribe(
      (data) => {
        this.sessionData = data;
        this.isSessionAvailable = !!data.session; // Check if session exists
        console.log('Session Data:', data);
      },
      (error) => {
        console.error('Error fetching session data:', error);
      }
    );
  
  }
 
}
