import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebshopserviceService   {


 
  private baseUrl =  environment.environmentWeb.webshopUrl; 
  private apiKey =  environment.environmentWeb.webshopsecretKey;

  constructor(private http: HttpClient) {}

  /**
   * Fetch session data by session ID.
   * 
   * @param sessionId - The session ID to query
   * @param onlyPaidPages - Whether to include only paid pages
   * @returns Observable with session data
   */
  getSessionData(sessionId: string, onlyPaidPages: boolean): Observable<any> {
    const url = `${this.baseUrl}/session_data`;
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'api-key': this.apiKey
    });

    const params = {
      session_id: sessionId,
      only_paid_pages: onlyPaidPages.toString()
    };

    return this.http.get(url, { headers, params });
  }


   /**
   * Reset print status for a specific printed page.
   * 
   * @param printedPageId - The ID of the printed page
   * @returns Observable with the response from the server
   */
   resetPrintStatus(printedPageId: number): Observable<any> {
    const url = `${this.baseUrl}/reset_print_status`;
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'api-key': this.apiKey
    });

    const params = { printedpage_id: printedPageId.toString() };

    return this.http.post(url, {}, { headers, params });
  }


   /**
   * Refund a transaction.
   * 
   * @param sessionId - The session ID
   * @param transactionId - The transaction ID
   * @param amount - The refund amount
   * @returns Observable with the response from the server
   */
   processRefund(sessionId: string, transactionId: number, amount: number): Observable<any> {
    const url = `${this.baseUrl}/refund`;
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'api-key': this.apiKey
    });

    const params = {
      session_id: sessionId,
      transaction_id: transactionId,
      amount: amount.toString()
    };

    return this.http.post(url, {}, { headers, params });
  }

  /**
 * Fetch file pages with specified parameters.
 * 
 * @param isPaid - Whether to filter for paid file pages
 * @param skip - Number of records to skip (pagination)
 * @param limit - Maximum number of records to return
 * @returns Observable with the response from the server
 */
getFilePages(isPaid: boolean, skip: number, limit: number): Observable<any> {
  const url = `${this.baseUrl}/filepages`;
  const headers = new HttpHeaders({
    'accept': 'application/json',
    'api-key': this.apiKey
  });

  const params = {
    is_paid: isPaid.toString(),
    skip: skip.toString(),
    limit: limit.toString()
  };

  return this.http.get(url, { headers, params });
}

}