<div class="row">
  <!-- First Column -->
  <div class="col-lg-6 col-md-12">
    <!-- Ticket Details Card -->
    <mat-card *ngIf="ticket" class="ticket-details-card">
      <mat-card-header class="ticket-card-header">
        <mat-card-title class="ticket-card-title">
          <mat-icon class="ticket-icon">assignment</mat-icon>
          Ticket Details
        </mat-card-title>
        <button mat-icon-button (click)="toggleEdit()" class="edit-button">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul class="ticket-details-list">
          <li><mat-icon class="list-icon">confirmation_number</mat-icon><strong>Ticket ID:</strong> {{ ticket?.ticketUserId }}</li>
          <li>
            <mat-icon class="list-icon">vpn_key</mat-icon><strong>Session ID:</strong> {{ ticket?.sessionId }}
            <button mat-icon-button (click)="copyToClipboard(ticket?.sessionId)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </li>
          <li><mat-icon class="list-icon">person</mat-icon><strong>Name:</strong> {{ ticket?.name }}</li>
          <li>
            <mat-icon class="list-icon">email</mat-icon><strong>Email:</strong> {{ ticket?.email }}
            <button mat-icon-button (click)="copyToClipboard(ticket?.email)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </li>
          <li>
            <mat-icon class="list-icon">phone</mat-icon><strong>Phone Number:</strong> {{ ticket?.phoneNumber }}
            <button mat-icon-button (click)="copyToClipboard(ticket?.phoneNumber)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </li>
          <li><mat-icon class="list-icon">category</mat-icon><strong>Category:</strong> {{ getCategoryText(ticket?.category) }}</li>
          <li><mat-icon class="list-icon">check_circle</mat-icon><strong>Status:</strong> {{ getStatusText(ticket?.status) }}</li>
          <li><mat-icon class="list-icon">source</mat-icon><strong>Source:</strong> {{ getSourceText(ticket?.source) }}</li>
          <li><mat-icon class="list-icon">warning</mat-icon><strong>Criticality:</strong> {{ getCriticalityText(ticket?.criticality) }}</li>
          <li><mat-icon class="list-icon">description</mat-icon><strong>Description:</strong> {{ ticket?.description }}</li>
          <li><mat-icon class="list-icon">date_range</mat-icon><strong>Created Date:</strong> {{ ticket?.created_at | date: 'dd.MM.yyyy HH:mm' }}</li>
          <li><mat-icon class="list-icon">update</mat-icon><strong>Last Change Date:</strong> {{ ticket?.lastModifiedDate | date: 'dd.MM.yyyy HH:mm' }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
    
     <!-- File Details Card -->
     <mat-card *ngIf="fileMetadata" class="file-metadata-card">
      <mat-card-header>
        <mat-card-title class="file-metadata-title">
          <mat-icon class="attachment-icon">attachment</mat-icon>
          Ticket Attachment
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul class="file-metadata-list">
          <li>
            <mat-icon class="list-icon">drive_file_rename_outline</mat-icon>
            <strong>Original Name:</strong> {{ fileMetadata.originalName }}
          </li>
          <li>
            <mat-icon class="list-icon">storage</mat-icon>
            <strong>Size:</strong> {{ fileMetadata.size }} bytes
          </li>
          <li>
            <mat-icon class="list-icon">category</mat-icon>
            <strong>Type:</strong> {{ fileMetadata.mimeType }}
          </li>
          <li>
            <mat-icon class="list-icon">cloud_download</mat-icon>
            <strong>Download:</strong>
            <a [href]="fileMetadata.fileUrl" target="_blank">Download File</a>
          </li>
        </ul>
        <div class="text-center file-action">
          <button mat-raised-button color="primary" (click)="openFile()" class="open-file-button">
            <mat-icon>open_in_new</mat-icon> Open File
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Edit Ticket Details Card -->
    <mat-card *ngIf="isEditing" class="edit-ticket-card">
      <mat-card-header>
        <mat-card-title class="edit-ticket-title">Edit Ticket Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul class="edit-ticket-list">
          <li class="edit-ticket-item">
            <strong>Category:</strong>
            <mat-select [(ngModel)]="ticket.category" class="edit-ticket-select">
              <mat-option *ngFor="let category of categories" [value]="category.id">
                {{ category.name }}
              </mat-option>
            </mat-select>
          </li>
          <li class="edit-ticket-item">
            <strong>Status:</strong>
            <mat-select [(ngModel)]="ticket.status" class="edit-ticket-select">
              <mat-option *ngFor="let status of statuses" [value]="status.id">
                {{ status.name }}
              </mat-option>
            </mat-select>
          </li>
          <li class="edit-ticket-item">
            <strong>Source:</strong>
            <mat-select [(ngModel)]="ticket.source" class="edit-ticket-select">
              <mat-option *ngFor="let source of sources" [value]="source.id">
                {{ source.name }}
              </mat-option>
            </mat-select>
          </li>
          <li class="edit-ticket-item">
            <strong>Criticality:</strong>
            <mat-select [(ngModel)]="ticket.criticality" class="edit-ticket-select">
              <mat-option *ngFor="let level of criticalities" [value]="level.id">
                {{ level.name }}
              </mat-option>
            </mat-select>
          </li>
        </ul>
        <div class="edit-ticket-actions text-center">
          <button mat-raised-button color="primary" (click)="saveChanges()" class="edit-ticket-button">
            Save
          </button>
          <button mat-raised-button color="warn" (click)="toggleEdit()" class="edit-ticket-button">
            Cancel
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    

  <!-- Add Note Card -->
<mat-card>
  <mat-card-header>
    <mat-card-title class="ticket-card-title">Add Note</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- Title Textbox -->

    <mat-form-field class="w-100"   appearance="outline">

      <input
        matInput
        placeholder="Enter the title here"
        [(ngModel)]="newTitle"
        class="w-100"
      

      />

    </mat-form-field>
    <!-- Note Textarea -->
    <mat-form-field class="w-100"   appearance="outline">
    <textarea
      matInput
      placeholder="Enter your note here"
      [(ngModel)]="newNote"
      rows="4"
      class="w-100"
    ></textarea>
  </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="createNote()"
      [disabled]="!newNote.trim() || !newTitle.trim()"
    >
      Add Note
    </button>
  </mat-card-actions>
</mat-card>

<!-- Notes Display Section -->
<mat-card *ngIf="loadingNotes" class="notes-loading-card">
  <mat-card-content>
    <mat-icon class="loading-icon">hourglass_empty</mat-icon>
    Loading notes...
  </mat-card-content>
</mat-card>

<mat-card *ngIf="!loadingNotes && notes.length === 0" class="notes-empty-card">
  <mat-card-content>
    <mat-icon class="empty-icon">note_alt</mat-icon>
    No notes available for this ticket.
  </mat-card-content>
</mat-card>

<div *ngIf="!loadingNotes && notes.length > 0" class="notes-list">
  <mat-card *ngFor="let note of notes" class="note-card">
    <mat-card-header>
      <mat-card-title class="note-title">
        <mat-icon class="note-icon">event_note</mat-icon>
        {{ note.action }}
      </mat-card-title>
      <mat-card-subtitle class="note-subtitle">
        <mat-icon class="user-icon">person</mat-icon>
        Created by: <strong>{{ userNames[note.created_by] || 'Support User' }}</strong> | 
        <mat-icon class="time-icon">schedule</mat-icon>
        {{ note.created_at | date: 'dd.MM.yyyy HH:mm' }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="note-content">
      <p>{{ note.note }}</p>
    </mat-card-content>
  </mat-card>


   
    </div>
    
  </div>

  <!-- Second Column -->
  <div class="col-lg-6 col-md-12">
    <app-session-details
      [sessionData]="sessionData"
      [isSessionAvailable]="isSessionAvailable"
    >
    </app-session-details>
  </div>
  
</div>
