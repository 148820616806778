<div *ngIf="isSessionAvailable; else noSession">
    <!-- Refund Section -->
    <mat-accordion *ngIf="this.isrefundStart === true">

      <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title class="accordion-title" style="color:rgb(3, 90, 141); font-size: large ; font-weight: 600;">Refund</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <h3 class="section-title">Payment Details</h3>
          <ul class="payment-details-list">
            <li><mat-icon class="list-icon">payment</mat-icon><strong>Total Paid Price:</strong> CHF {{ sessionData.data.total_paid_price / 100 }}</li>
            <li><mat-icon class="list-icon">description</mat-icon><strong>Total Paid Pages:</strong> {{ sessionData.data.total_paid_filepages }}</li>
            <li><mat-icon class="list-icon">content_copy</mat-icon><strong>Total Paid Copies:</strong> {{ sessionData.data.total_paid_copies }}</li>
            <li><mat-icon class="list-icon">print</mat-icon><strong>Total Printed Pages:</strong> {{ sessionData.data.total_printedpages }}</li>
          </ul>
          <!-- Refund Form -->
          <div class="refund-form">
            <mat-form-field appearance="outline" class="refund-input">
           
              <input matInput type="text" placeholder="Enter Transaction ID" [(ngModel)]="refundTransactionId"  disabled/>
            </mat-form-field>
            <mat-form-field appearance="outline" class="refund-input">
             
              <input matInput type="number" placeholder="Enter Refund Amount" [(ngModel)]="refundAmount" />
            </mat-form-field>
            <button mat-raised-button color="warn" (click)="onRefund(sessionData.session.session_id)" [disabled]="!refundTransactionId || !refundAmount || refundAmount <= 0">
              Process Refund
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  
    <!-- File Pages Accordion -->
    <mat-accordion>
      <div *ngFor="let file of sessionData.filepages">
        <!-- Accordion Panel -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="accordion-title">
              File: {{ file.filename }} (Page ID: {{ file.id }})
            </mat-panel-title>
            <mat-panel-description>
              <!-- Chips for Print Status and Paid Status -->
              <mat-chip 
                *ngIf="file.is_paid" 
                [ngClass]="{ 'paid-chip': true, 'highlight-chip': file.is_paid }">
                <mat-icon>payment</mat-icon>
              </mat-chip>
              <mat-chip 
                *ngIf="isPrintedPageAvailable(file.id)" 
                [ngClass]="{ 'reprint-chip': true, 'highlight-chip': isPrintedPageAvailable(file.id) }">
                <mat-icon>printer</mat-icon>
              </mat-chip>
            </mat-panel-description>
          </mat-expansion-panel-header>
  
          <!-- File Page Details -->
          <div>
            <h3 class="section-title">File Page Details</h3>
            <ul class="file-details-list">
              <li><mat-icon class="list-icon">insert_drive_file</mat-icon><strong>File Name:</strong> {{ file.filename }}</li>
              <li><mat-icon class="list-icon">dashboard</mat-icon><strong>Session ID:</strong> {{ file.session_id }}</li>
              <li><mat-icon class="list-icon">layers</mat-icon><strong>Paper Type:</strong> {{ file.print_paper_type }}</li>
              <li>
                <mat-icon class="list-icon">check_circle</mat-icon>
                <strong>Paid:</strong>
                {{ file.paid_at ? file.paid_at : 'No' }}
              </li>
              <li>
                <mat-icon class="list-icon">receipt_long</mat-icon>
                <strong>Transaction ID:</strong>
                {{ file.transaction_id }}
                <button mat-raised-button color="warn" matTooltip="Start Refund" class="refund-button" (click)="refundStart(file.transaction_id)">
                    <mat-icon>refresh</mat-icon>
                    Start Refund
                  </button>
                  
              </li>
              <li><mat-icon class="list-icon">money</mat-icon><strong>Price:</strong> CHF {{ file.price / 100 }}</li>
            </ul>
          </div>
  
          <!-- See More Section -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>See More</mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <ul class="file-details-list">
                <li><mat-icon class="list-icon">folder</mat-icon><strong>File Location:</strong> {{ file.file_location }}</li>
                <li><mat-icon class="list-icon">straighten</mat-icon><strong>Print Width:</strong> {{ file.print_width }} mm</li>
                <li><mat-icon class="list-icon">straighten</mat-icon><strong>Print Height:</strong> {{ file.print_height }} mm</li>
                <li><mat-icon class="list-icon">crop_din</mat-icon><strong>Width:</strong> {{ file.width }} mm</li>
                <li><mat-icon class="list-icon">crop_din</mat-icon><strong>Height:</strong> {{ file.height }} mm</li>
                <li><mat-icon class="list-icon">content_copy</mat-icon><strong>Copies:</strong> {{ file.copies }}</li>
                <li><mat-icon class="list-icon">pageview</mat-icon><strong>Page Number:</strong> {{ file.page }}</li>
                <li><mat-icon class="list-icon">palette</mat-icon><strong>Color:</strong> {{ file.has_color ? 'Yes' : 'No' }}</li>
                <li><mat-icon class="list-icon">border_outer</mat-icon><strong>Border:</strong> {{ file.has_border ? 'Yes' : 'No' }}</li>
                <li><mat-icon class="list-icon">block</mat-icon><strong>Disabled:</strong> {{ file.disabled ? 'Yes' : 'No' }}</li>
              </ul>
            </div>
          </mat-expansion-panel>
  
          <!-- Printed Page Details -->
          <div *ngIf="file.is_paid">
            <h3 class="section-title">Printed Page Details</h3>
            <ng-container *ngIf="getPrintedPages(file.id)?.length > 0; else noPrintedMessage">
              <table mat-table [dataSource]="getPrintedPages(file.id)" class="mat-elevation-z8 printed-details-table">
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> ID </th>
                  <td mat-cell *matCellDef="let printed"> {{ printed.id }} </td>
                </ng-container>
  
                <!-- Panel ID Column -->
                <ng-container matColumnDef="panelId">
                  <th mat-header-cell *matHeaderCellDef> Panel ID </th>
                  <td mat-cell *matCellDef="let printed"> {{ printed.panel_id }} </td>
                </ng-container>
  
                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let printed">
                    <button mat-raised-button color="primary" (click)="onReprint(printed.id)" matTooltip="Printer ID: {{ printed.printer_id }}, Printed At: {{ printed.printed_at }}" *ngIf="printed.is_printed">
                      Reprint
                    </button>
                    <span *ngIf="!printed.is_printed"> Ready for Reprint</span>
                  </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="['id', 'panelId', 'action']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['id', 'panelId', 'action'];"></tr>
              </table>
            </ng-container>
  
            <!-- No Printed Pages Message -->
            <ng-template #noPrintedMessage>
              <div class="no-printed-message">
                <mat-icon color="warn" style="margin-top: 15px;">info</mat-icon>
                <span>File not printed yet</span>
              </div>
            </ng-template>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
  
  <ng-template #noSession>
    <mat-card>
      <mat-card-header>
        <mat-card-title>No Session Data Available</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>No data available for the provided session ID.</p>
      </mat-card-content>
    </mat-card>
  </ng-template>
  