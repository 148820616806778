<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    (click)="openDialog()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
  </button>



  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->


  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <img
      [src]="selectedLanguage.icon"
      class="rounded-circle object-cover icon-20"
    />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    @for(lang of languages; track lang.icon) {
    <button mat-menu-item (click)="changeLanguage(lang)">
      <div class="d-flex align-items-center">
        <img
          [src]="lang.icon"
          class="rounded-circle object-cover m-r-8 icon-20"
        />
        <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
      </div>
    </button>
    }
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
 

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Notifications"
  >
    <img
      src="/assets/images/profile/user-1.jpg"
      class="rounded-circle object-cover icon-35 profile-dd"
      width="35"
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 300px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img
            src="/assets/images/profile/user-1.jpg"
            class="rounded-circle"
            width="95"
          />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0 mat-subtitle-1">TG Soft</h6>
            <span class="f-s-14 d-block m-b-4">Super Admin</span>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              info&#64;signpring.ch
            </span>
          </div>
        </div>
      </div>
     
      <div class="p-y-12 p-x-32">
        <button
          mat-stroked-button
          color="primary"
          class="w-100"
          (click)="logout()"
        >
          Logout
        </button>
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
