import { Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AppTicketlistComponent } from './pages/ticketlist/ticketlist.component';
import { DeviceTableComponent } from './pages/device-health/device.component';
import { AccessTableComponent } from './pages/Access/access.component';
import { CreateTicketComponent } from './pages/createTicket/create-ticket/create-ticket.component';
import { GlobalticketComponent } from './pages/global/globalticket/globalticket.component';
import { authGuard } from './guards/auth.guard'; // Import the authGuard
import { TicketviewComponent } from './pages/ticketview/ticketview.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboards/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboards',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./pages/dashboards/dashboards.routes').then(
            (m) => m.DashboardsRoutes
          ),
      },
      {
        path: 'settings',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./pages/other-pages/theme-pages.routes').then(
            (m) => m.ThemePagesRoutes
          ),
      },
      {
        path: 'orders',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./pages/tables/tables.routes').then((m) => m.TablesRoutes),
      },
      {
        path: 'dashboards/tickets',
        canActivate: [authGuard],
        component: AppTicketlistComponent,
        data: {
          title: 'Tickets',
          urls: [
            { title: 'Tickets', url: 'dashboards/tickets' },
            { title: 'Tickets' },
          ],
        },
      },

      {
        path: 'dashboards/ticketsview',
        canActivate: [authGuard],
        component: TicketviewComponent,
        data: {
          title: 'Tickets',
          urls: [
            { title: 'Tickets', url: 'dashboards/ticketsview' },
            { title: 'Tickets' },
          ],
        },
      },
      {
        path: 'dashboards/orderdetails',
        canActivate: [authGuard],
        component: OrderDetailsComponent,
        data: {
          title: 'Order Details',
          urls: [
            { title: 'Order', url: 'dashboards/orderdetails' },
            { title: 'Order' },
          ],
        },
      },
      {
        path: 'dashboards/device',
        canActivate: [authGuard],
        component: DeviceTableComponent,
        data: {
          title: 'Device List',
          urls: [
            { title: 'Device List', url: 'dashboards/device' },
            { title: 'Device List' },
          ],
        },
      },
      {
        path: 'dashboards/access',
        canActivate: [authGuard],
        component: AccessTableComponent,
        data: {
          title: 'Access Management',
          urls: [
            { title: 'Access Management', url: 'dashboards/access' },
            { title: 'Access Management' },
          ],
        },
      },

      {
        path: 'dashboards/createticket',
        canActivate: [authGuard],
        component: CreateTicketComponent,
        data: {
          title: 'CreateTicket',
          urls: [
            { title: 'Create A Ticket', url: 'dashboards/createticket' },
            { title: 'Create A Ticket' },
          ],
        },
      },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.routes').then(
            (m) => m.AuthenticationRoutes
          ),
      },
      
    ],
  },

  {
    path: 'ticket',
    component: GlobalticketComponent,
    canActivate: [authGuard],
    data: {
      title: 'CreateTicket',
      urls: [
        { title: 'Create Ticket', url: 'ticket' },
        { title: 'Create Ticket' },
      ],
    },
  },

  {
    path: '**',
    redirectTo: 'authentication/login',
  },
];
